<template>
    <v-container fluid>

        <v-layout row :style="$vuetify.breakpoint.smAndDown ? {height: '80px'} : {height: '80px'}">
            <v-app-bar height="100" app elevation="3"  color="#ffffff" class="hidden-sm-and-down navbar mx-auto">


                <v-container style="max-width: 1261px">
                    <v-layout row>
                    





                        <v-toolbar-title style="min-width:270px">
                            <router-link class="height" :to="'/?lang='+lang">
                                <v-img contain :max-height="isLang() ? '75' : '65'" max-width="360" src="/assets/logo.png" alt=""></v-img>
                            </router-link>
                        </v-toolbar-title>
                        <v-toolbar-items>
                            <template v-if="loading">
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                            </template>
                            <template v-else >
                                <div v-for="item in nav.slice(0, 6)" :key="item">  
                                    <template >  
                                        <v-menu offset-y offset-overflow left open-on-hover open-delay="500">
                                            
                                            <template v-slot:activator="{ on, attrs }" v-if="item.visible == 1">
                                                
                                                <template>
                                                    <v-btn elevation="0" :style="!isLang() ? 'margin-top: 15px;' : 'margin-top: 7px;'" class="nav_btn" color="rgba(0,0,0,0)" :title="$t(item.word)" v-bind="attrs" v-on="on">
                                                        <router-link :to="item.url ? item.url+'?lang=' + $i18n.locale : ''" class="text-light sp">{{ $t(item.word) }}</router-link>
                                                        <span v-if="item.sub" style="content: url('/assets/img/arrow_down.svg'); width:10px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
                                                    </v-btn>
                                                </template>
                                                
                                            </template>




                                            <v-list class="messiri_fonted" color="rgba(0,0,0,.7)" v-if="item.sub" >
                                                <template v-for="(t, index) in JSON.parse(item.sub)" >
                                                    <template v-if="t.visible == 1">
                                                        
                                                            <v-list-item class="list_height" :key="index">
                                                                <v-list-item-title>
                                                                    <router-link :to="t.url+'?lang=' + $i18n.locale" class="white--text sub_nav">
                                                                    <span style="content: url('/assets/img/footer_title_ico.svg');" class="related_sub_item"></span>
                                                                        {{ $t(t.word) }}
                                                                    </router-link>
                                                                </v-list-item-title>
                                                            </v-list-item>

                                                    </template>
                                                </template>
                                            </v-list>







                                        </v-menu>
                                    </template>
                                </div>
                            </template>
                        </v-toolbar-items>


                        <template v-if="isLang()">
                            <a href="/?lang=ar" style="margin-left: auto;right: 0; margin-top: 17px">
                                <v-btn @click="change('ar')" elevation="0" dark color="rgba(20, 92, 94,1)">العربية</v-btn>
                            </a>
                        </template>
                        <template v-else>
                            <a href="/?lang=en" style="margin-right: auto;left: 0; margin-top: 17px">
                                <v-btn elevation="0" @click="change('en')" dark color="rgba(20, 92, 94,1)">English</v-btn>
                            </a>
                        </template>


                    </v-layout>
                </v-container>
            </v-app-bar>

            
            
            <v-app-bar style="background-color:#fff !important;" app  elevation="2"  class="hidden-md-and-up navbar_m mx-auto">
                <v-toolbar-title class="height">
                    <router-link class="height" to="#">
                        <v-img dark contain height="50"  position="right center" :src="!isLang() ? 'https://alkafeel.net/src/svg/logo5.svg' : 'https://alkafeel.net/uploads/'+logo" alt=""></v-img>
                    </router-link>
                </v-toolbar-title>
                <v-spacer></v-spacer>


                <v-app-bar-nav-icon @click.stop="drawer = !drawer">
                    <div style="fill:#b5b5b5; width:30px; margin-top: 5px"><svg viewBox="0 0 35 25"><use xlink:href="/assets/img/burger_menu.svg#view"></use></svg></div>
                </v-app-bar-nav-icon>

                <v-navigation-drawer style="padding: 20px" app class="drawer" color="rgba(255,255,255,1)" temporary disable-resize-watcher v-model="drawer" absolute right="true">
                    <v-list nav dense>
                        <v-list-item-group v-model="group" active-class="text--accent-4" >

                            <v-list-item-title style="margin-bottom: 20px">
                                <v-img contain :height="dir === 'ltr' ? '30' : '50'" :position="dir === 'rtl' ? 'right center' : 'left center'" alt="" :src="!isLang() ? 'https://alkafeel.net/src/svg/logo5.svg' : 'https://alkafeel.net/uploads/'+logo">
                                    <div class="fill-height" :style="dir === 'rtl' ? 'text-align: left' : 'text-align: right'">
                                        <img height="20" style="cursor:pointer;" src="/assets/img/t-mnue.svg" @click.stop="drawer = false">
                                    </div>
                                </v-img>
                            </v-list-item-title>

                            <template v-for="item in nav">
                                <template>
                                    
                                    <v-list-group :key="item">
                                        <template v-slot:activator>
                                            <v-list-item-title v-if="item.visible == 1" :title="$t(item.word)">
                                                <router-link :to="item.url ? item.url+'?lang=' + $i18n.locale : ''" class="navMColor navSize">{{ $t(item.word) }}</router-link>
                                            </v-list-item-title>
                                        </template>

                                        <v-list-group class="messiri_fonted" :append-icon="false" v-for="(t, index) in JSON.parse(item.sub)" :key="index">
                                            <template v-slot:activator>
                                                <div v-if="item.sub">
                                                    <!-- <a class="navMColor sub_nav_m" :href="t.url" v-if="t.visible == 1">  -->
                                                    <router-link :to="t.url+'?lang=' + $i18n.locale" class="navMColor sub_nav_m">
                                                        <span style="content: url('/assets/img/footer_title_ico.svg');" class="related_sub_item"></span> 
                                                        {{ $t(t.word) }} 
                                                    </router-link>
                                                        
                                                        
                                                </div>
                                            </template>
                                        </v-list-group>

                                        <span v-if="item.sub" slot="appendIcon" style="content: url('/assets/img/arrow_down.svg'); width:10px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
                                    </v-list-group>
                                </template>

                                
                            </template>


                            

                        </v-list-item-group>
                    </v-list>
                </v-navigation-drawer>

            </v-app-bar>

        </v-layout>


        



    </v-container>
</template>
<script>
import axios from "axios";
let nav   = 'https://alkafeel.net/main_bg/data/Holy_quran_nav.json';
let langs = 'https://alkafeel.net/main_bg/data/core_langs_main.json';
const requestNav   = axios.get(nav);
const requestlangs = axios.get(langs);
export default {
    data() {
        return {
            drawer: false,
            group: null,
            dialog: false,
            lang: 'ar',
            dir: 'rtl',
            logo: 'logo5.svg',
            nav: [],
            langs: [],
            loading: true,

            langsOverlay: false,
        }
    },
    watch: {
        group () {
            this.group = false
        }
    },
    created () {
        // this.$vuetify.rtl = true;
        this.initialize();
    },
    methods: {
        change(lang){

             localStorage.setItem("lang",lang);

        },
        initialize() {
            axios
                .all([requestNav, requestlangs])
                .then(
                axios.spread((...responses) => {
                    this.loading = false;
                    const responseNav   = responses[0].data;
                    const responseLangs = responses[1].data;
                    this.nav = responseNav;
                    this.langs = responseLangs;
                })
            )
            .catch(errors => {
                console.error(errors);
            });
        },
        lang_dt (url) {
            if (url === undefined) {
                this.lang_ck ('ar');
                return;
            }
            this.lang_ck (url)
        },
        lang_ck (code) {

            // Exception for other langs
            if (code !== 'ar' && code !== 'en') {
                code = 'ar';
            }

            this.langs.forEach(element => {
                if (element.code === code) {
                       
                    this.lang = code;
                    this.$i18n.locale=code;
                    this.dir = element.dir == '1' ? 'rtl' : 'ltr';
                    this.logo = element.logo;

                    this.$store.dispatch("UpdateLang",{lang:this.lang,dir:this.dir});

                    this.$vuetify.rtl = this.dir === 'rtl' ? true : false;
                    document.title = this.$t('center');

                   
                    axios.defaults.headers.get['lang']=this.$i18n.locale;
                 


                    document.documentElement.setAttribute('lang', code)
                }
            });
        },
        isLang () {
            if (this.lang !== 'ar') {
                return true;
            }
            return false;
        },

    },
    updated() {
       
       
         
        



           
       if(this.$route.query.lang==undefined)
       {
localStorage.setItem("lang",'ar');
this.lang_dt('ar');
       }
       else{
         localStorage.setItem("lang",this.$route.query.lang);
         this.lang_dt(this.$route.query.lang);
       }



    },
     
}
</script>
<style scoped>
    .v-toolbar__items {
        margin: 0 35px;
    }
    .social a {
        padding: 16px;
    }
    @media (max-width: 1111px) {
        .social {
            display: none!important;
        }
    }
    .footer_desc {
        color: #5B5B5B;
        font-size: 14px;
    }
    .messiri_fonted * {
        font-family: 'el_messiri', arial;
    }
    .border {
        border-bottom:solid thin #D6D6D6
    }
    .drawer {
        position: absolute;
        top:0!important;
        right:0;
        left:0;
        width:100%!important;
        bottom: 0;
    }
    .navbar {
        height: auto!important;
        transition: .3s ease-in-out all;
    }
    .navbar a {
        text-decoration: none;
    }
    .navbar_m {
        transition: .3s ease-in-out all;
    }
    .navMColor {
        color:#5E5E5E
    }
    .navSize {
        font-size: 18px;
        text-decoration: none;
    }
    .v-list-group--active .v-list-group__header__append-icon span {
        transform: rotate(180deg);
    }
    .sub_nav_m {
        text-decoration: none;
        font-size: 15px;
    }
    .sp {
        letter-spacing: normal;
    }
    .list_height {
        min-height: 35px;
    }
    .sub_nav {
        text-decoration: none;
        font-size: 14px;
        line-height: 15px;
    }
    .nav_btn {
        box-shadow: none;
        font-size: 16px;
        font-weight: bolder;
    }
    .nav_btn a {
        color: #312F2F;
    }
    .nav_btn:hover * {
        color: #B1BD52 !important;
    }
    .related_sub_item {
        width:12px; 
        height: 12px; 
        display: ruby-text-container;
        margin: 0 5px;
    }
    .related_links {
        color:#5B5B5B; 
        text-decoration: none;
        font-weight: 700;
        opacity: .7;
        font-size: 18px;
        line-height: 30px;
    }
    .height {
        width: 100%;
        height: 100%;
    }
</style>